<template>
  <div>
    <div
      id="dropContainer"
      class="box has-advanced-upload"
      style="border:1px solid black;height:100px;"
      @drop="handleChange"
    >
      Drop File Here
    </div>
    <!-- Should update here: -->
    <div class="mt-1"></div>
    <input
      type="file"
      id="fileInput"
      @change="handleChange"
      style="width:600px"
      accept="application/pdf, application/vnd.ms-excel"
    />
    <button @click="clearInputFile()">Clear</button>
  </div>
</template>

<script>
// https://css-tricks.com/drag-and-drop-file-uploading/
// css
export default {
  components: {},
  data() {
    return {};
  },
  mounted() {
    let fileInput = document.getElementById("fileInput");

    dropContainer.ondragover = dropContainer.ondragenter = function(evt) {
      evt.preventDefault();
    };

    dropContainer.ondrop = evt => {
      // pretty simple -- but not for IE :(
      fileInput.files = evt.dataTransfer.files;
      let file = evt.dataTransfer.files[0];

      if (file) {
        this.handleFile(file);
      }
      evt.preventDefault();
    };

    $("#dropContainer").bind("dragover dragenter", function() {
      $(this).addClass("is-dragover");
    });
    $("#dropContainer").bind("dragleave dragend drop", function() {
      $(this).removeClass("is-dragover");
    });
  },
  methods: {
    handleChange(e) {
      let file;
      if (e.target && e.target.files) {
        file = e.target.files[0];
      }

      if (file) {
        this.handleFile(file);
      }
    },
    handleFile(file) {
      // er("aa0", file);
      this.$emit("file-change", file);
    },
    clearInputFile() {
      document.getElementById("fileInput").value = null;
    }
  }
};
</script>

<style>
.box {
  font-size: 1.25rem; /* 20 */
  background-color: #c8dadf;
  position: relative;
  padding: 100px 20px;
}
.box.has-advanced-upload {
  outline: 2px dashed #92b0b3;
  outline-offset: -10px;

  -webkit-transition: outline-offset 0.15s ease-in-out, background-color 0.15s linear;
  transition: outline-offset 0.15s ease-in-out, background-color 0.15s linear;
}
.box.is-dragover {
  outline-offset: -20px;
  outline-color: #c8dadf;
  background-color: #fff;
}
.box__dragndrop,
.box__icon {
  display: none;
}
.box.has-advanced-upload .box__dragndrop {
  display: inline;
}
.box.has-advanced-upload .box__icon {
  width: 100%;
  height: 80px;
  fill: #92b0b3;
  display: block;
  margin-bottom: 40px;
}

.box.is-uploading .box__input,
.box.is-success .box__input,
.box.is-error .box__input {
  visibility: hidden;
}

.box__uploading,
.box__success,
.box__error {
  display: none;
}
.box.is-uploading .box__uploading,
.box.is-success .box__success,
.box.is-error .box__error {
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;

  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.box__uploading {
  font-style: italic;
}
.box__success {
  -webkit-animation: appear-from-inside 0.25s ease-in-out;
  animation: appear-from-inside 0.25s ease-in-out;
}
@-webkit-keyframes appear-from-inside {
  from {
    -webkit-transform: translateY(-50%) scale(0);
  }
  75% {
    -webkit-transform: translateY(-50%) scale(1.1);
  }
  to {
    -webkit-transform: translateY(-50%) scale(1);
  }
}
@keyframes appear-from-inside {
  from {
    transform: translateY(-50%) scale(0);
  }
  75% {
    transform: translateY(-50%) scale(1.1);
  }
  to {
    transform: translateY(-50%) scale(1);
  }
}

.box__restart {
  font-weight: 700;
}
.box__restart:focus,
.box__restart:hover {
  color: #39bfd3;
}
</style>
