<script>
export default {
  methods: {
    async convertToImagesBase64(file, func) {
      let results = [];
      if (!file) {
        return;
      }

      let base64 = await this.imgToBase64(file);
      let arr = base64.split(",");
      let base64Data = arr[1];

      var PDFJS = window["pdfjs-dist/build/pdf"];

      PDFJS.GlobalWorkerOptions.workerSrc = "//mozilla.github.io/pdf.js/build/pdf.worker.js";

      var loadingTask = PDFJS.getDocument({ data: atob(base64Data) });

      loadingTask.promise.then(
        function(pdf) {
          var canvasdiv = document.getElementById("canvas");
          var totalPages = pdf.numPages;
          var data = [];

          for (let pageNumber = 1; pageNumber <= totalPages; pageNumber++) {
            pdf.getPage(pageNumber).then(function(page) {
              var scale = 1.5;
              var viewport = page.getViewport({ scale: scale });

              var canvas = document.createElement("canvas");
              canvasdiv.appendChild(canvas);

              // Prepare canvas using PDF page dimensions
              var context = canvas.getContext("2d");
              canvas.height = viewport.height;
              canvas.width = viewport.width;

              // Render PDF page into canvas context
              var renderContext = { canvasContext: context, viewport: viewport };

              var renderTask = page.render(renderContext);

              renderTask.promise.then(function() {
                let arr = canvas.toDataURL("image/png").split(",");
                let result = arr[1];
                // results.push(result);
                results.push({
                  pageNumber: pageNumber,
                  result: result
                });

                if (results.length == totalPages) {
                  //   console.log("ggg", results);
                  //   return results;
                  func(results);
                }

                // console.log(`page ${pageNumber}`, canvas.toDataURL("image/png"));

                // data.push(canvas.toDataURL("image/png"));
                // console.log(data.length + " page(s) loaded in data");
              });
            });
          }
        },
        function(reason) {
          // PDF loading error
          console.error(reason);
        }
      );
    },
    imgToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    }
  }
};
</script>
